import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation,useNavigationType } from "react-router-dom";
import Home from "./pages/Home";
import ProductPage from "./pages/ProductPage";
import ProductDetailPage from "./pages/ProductDetail";
import ProductCards from "./pages/ProductCard";
import axios from "axios";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import { LoadingBarContainer, useLoadingBar } from "react-top-loading-bar";

import News from "./pages/News";

export const Context = createContext();

function MainApp() {
  const { start, complete } = useLoadingBar();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [detailpage,setDetailPage] = useState(null)

  const navigate = useNavigate();



async  function detailNews(item){
  const query = await  axios.get('https://backend.avtosaltanat.uz/news/api/news/'+item.id+'/')
  console.log(query.data);
  setDetailPage(query.data)
  console.log(detailpage);
  
  navigate('/news/'+item.id)
  
  
  }
  // Fetch products and category
  useEffect(() => {
    const savedProducts = localStorage.getItem("products");
    if (savedProducts) {
      setProducts(JSON.parse(savedProducts));
    } else {
      fetchProduct();
    }

    const savedCategory = localStorage.getItem("category");
    if (savedCategory) {
      setCategory(JSON.parse(savedCategory));
    } else {
      fetchCategory();
    }
  }, []);

  const fetchProduct = async () => {
    try {
      const query = await axios.get("https://backend.avtosaltanat.uz/pro/api/products/");
      setProducts(query.data);
      localStorage.setItem("products", JSON.stringify(query.data));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchCategory = async () => {
    try {
      const query = await axios.get("https://backend.avtosaltanat.uz/pro/api/category/");
      setCategory(query.data);
      localStorage.setItem("category", JSON.stringify(query.data));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchCategoryComp = async (category) => {
    try {
      const query = await axios.get("https://backend.avtosaltanat.uz/pro/api/products/");
      const filteredData = query.data.filter((c) => c.category.title === category);
      setSelectCategory(filteredData);
      navigate("/products/detail");
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  const fetchProductDetail = async (id) => {
    try {
      const query = await axios.get(`https://backend.avtosaltanat.uz/pro/api/products/${id}/`);
      setSelectProduct(query.data);
      navigate("/products/detail/" + id);
    } catch (error) {
      console.error("Error fetching product detail:", error);
    }
  };

  return (
    <Context.Provider value={{detailNews,detailpage, products, category, fetchCategoryComp, selectCategory, fetchProductDetail, selectProduct }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/products/detail/" element={<ProductCards />} />
        <Route path="/products/detail/:id" element={<ProductDetailPage />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/news/:id" element={<News />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Context.Provider>
  );
}

function App() {
  return (
    <LoadingBarContainer>
      <MainApp />
    </LoadingBarContainer>
  );
}

export default App;
