import React from "react";

const GoogleMap = () => {
  return (
    <div style={{textAlign:"center"}}>
        <h1>Xaritada Joylashgan Joyimiz</h1>
        <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21221.87059189018!2d64.501350233346!3d39.73465812963442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sus!4v1743684166749!5m2!1sen!2sus"
  width={'95%'}
  height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

    </div>
  );
};

export default GoogleMap;
