"use client"

import axios from "axios"
import { useContext, useEffect, useState } from "react"
import Navbar from "../navbar/Navbar"
import { Footer } from "../footer/Footer"
import { Context } from "../../App"

const newsItems = [
  {
    id: 1,
    date: "8 Noyabr 2024",
    title: "SamAuto jamoasi mutaxassisi ISUZU Auto Mechanics xalqaro tanlovining sovrindori bo'ldi!",
    image: "https://source.unsplash.com/random/600x400/?mechanic",
    link: "/news/1",
  },
  {
    id: 2,
    date: "24 Oktyabr 2024",
    title: "SamAuto Toshkentdagi «Komtrans» xalqaro ko'rgazmasida birdaniga ikkita yangi mahsulotini taqdim etdi!",
    image: "https://source.unsplash.com/random/600x400/?bus-exhibition",
    link: "/news/2",
  },
  {
    id: 3,
    date: "22 Iyul 2024",
    title: "SamAuto ISUZU D-MAX pikap egalari uchun klub ochmoqda!",
    image: "https://source.unsplash.com/random/600x400/?pickup-club",
    link: "/news/3",
  },
  {
    id: 4,
    date: "8 Aprel 2024",
    title: "ISUZU yuk mashinalari 18% dan kreditga!",
    image: "https://source.unsplash.com/random/600x400/?truck-finance",
    link: "/news/4",
  },
]

export function NewsSection({}) {
  const [news,setNews] = useState([])
  const {detailNews} = useContext(Context)


  useEffect(()=>{
    axios.get('https://backend.avtosaltanat.uz/news/api/news/').then(res=>{
      setNews(res.data)
      console.log(res.data);
      
    })
  },[])
  return (
    <div>
    <section style={{ padding: "40px 0", maxWidth: "1200px", margin: "0 auto" }}>
      <h1
        style={{
          fontSize: "36px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "40px",
          color: "#222",
        }}
      >
        Yangiliklar
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {news.map((item) => (
          <div 
          onClick={()=>detailNews(item)}
            key={item.id}
            style={{
              width: "270px",
              height: "350px",
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              transition: "transform 0.3s ease",
              cursor: "pointer",
            }}
            onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
            onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
          >
            <img
              src={item.image || "/placeholder.svg"}
              alt={item.title}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.8))",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: "20px",
                left: "20px",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "4px 8px",
                borderRadius: "4px",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
            >
              {item.created_at}
            </div>
            <h3
              style={{
                position: "absolute",
                bottom: "20px",
                left: "20px",
                right: "20px",
                color: "white",
                fontSize: "18px",
                fontWeight: "bold",
                margin: 0,
                lineHeight: "1.4",
              }}
            >
              {item.title}
            </h3>
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
        {/* <button 
          style={{
            padding: "10px 20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "white",
            fontSize: "16px",
            cursor: "pointer",
            transition: "all 0.3s ease",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = "#f5f5f5"
            e.currentTarget.style.borderColor = "#999"
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = "white"
            e.currentTarget.style.borderColor = "#ccc"
          }}
        >
          Barcha yangiliklar
        </button> */}
      </div>
    </section>
    </div>
  )
}

