import React, { useContext } from 'react'
import './News.css'
import Navbar from '../components/navbar/Navbar'
import { Footer } from '../components/footer/Footer'
import { Link } from 'react-router-dom'
import { Context } from '../App'
function News() {
    const {detailpage} = useContext(Context)
  return (
    <div>
        <Navbar />
              {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <Link to="/">Asosiy sahifa</Link>
            </li>
          
            <li>Yangiliklar</li>
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <main className="main-content">
        <div className="container">
          <h1 className="page-title">Yangiliklar</h1>

          <article className="news-article">
            <h2 className="article-title">
            {detailpage?.title}
            </h2>

            <div className="article-content">
              <p>
                {detailpage?.description}
              </p>
            
            </div>
            {/* <img src={detailpage?.image} width={'90%'}  alt="" /> */}
          </article>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default News