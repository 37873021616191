import { useEffect, useState, useContext } from "react";
import { Footer } from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import { Link, useParams } from "react-router-dom";
import "./ProductDetail.css";
import { Context } from "../App";

function ProductDetailPage() {
  const { id } = useParams();
  const { selectProduct } = useContext(Context);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // LocalStorage'dan mahsulot ma'lumotlarini olish
    const storedProduct = JSON.parse(localStorage.getItem(`product-${id}`));
    
    // Agar localStorage'da mavjud bo'lsa, selectProduct'dan olingan ma'lumotni saqlaymiz
    if (storedProduct) {
      setProduct(storedProduct);
    } else {
      // Agar localStorage'da ma'lumot bo'lmasa, selectProduct'dan foydalanamiz
      setProduct(selectProduct);
    }
  }, [id, selectProduct]);

  // Agar mahsulot ma'lumotlari yuklanmagan bo'lsa, loading holatini ko'rsatish
  if (!product) {
    return (
      <div className="loading">
        <p>Yuklanmoqda...</p>
      </div>
    );
  }

  return (
    <div className="bus-detail-page" data-aos="zoom-out">
      {/* Header */}
      <Navbar />

      {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <div className="container">
          <div className="breadcrumbs-list">
            <a href="#" className="breadcrumb-item">
              Asosiy sahifa
            </a>
            <span className="breadcrumb-separator">•</span>
            <Link to="/products" className="breadcrumb-item">
              Mahsulotlar
            </Link>
            <span className="breadcrumb-separator">•</span>
            <Link to="/" className="breadcrumb-item">
              {product.category?.title || "Kategoriya"}
            </Link>
            <span className="breadcrumb-separator">•</span>
            <span className="breadcrumb-item active">{product.title}</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="main-content">
        <div className="container">
          <h1 className="page-title" style={{ textTransform: "uppercase" }}>
            {product.title}
          </h1>

          <div className="product-details">
            <div className="product-description">
              <p>{product.description}</p>
              <button className="button-89" role="button">
                {product.price}00,000 so'm
              </button>

              <Link
                to={product.file}
                download
                target="_blank"
                className="compare-button button-86"
              >
                Yuklab olish
              </Link>
            </div>
            <div className="product-image">
              <img
                src={product.image}
                alt={product.title || "Mahsulot rasmi"}
                className="bus-image"
              />
            </div>
          </div>

          <section className="technical-specs" data-aos="zoom-out">
            <h2 className="section-title">Texnik tasnifi</h2>

            <div className="specs-grid">
              <div className="spec-item">
                <div className="spec-value">
                  {product.technical_specification.max_weight} kg
                </div>
                <div className="spec-label">Ruxsat etilgan yalpi vazn</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">
                  {product.technical_specification.dimensions}
                </div>
                <div className="spec-label">Umumiy o'lchamlar (U x G x Y), mm</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">
                  {product.technical_specification.engine_volume}
                </div>
                <div className="spec-label">Dvigatel modeli</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">
                  {product.technical_specification.cargo_capacity} sm<sup>3</sup>
                </div>
                <div className="spec-label">Ish hajmi</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">
                  {product.technical_specification.wheelbase} mm
                </div>
                <div className="spec-label">G'ildirak bazasi</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">
                  {product.technical_specification.max_power}
                </div>
                <div className="spec-label">Maksimal dvigatel kuchi</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">
                  {product.technical_specification.fuel_type}
                </div>
                <div className="spec-label">Yoqilg'i turi</div>
              </div>

              <div className="spec-item">
                <div className="spec-value">4x2</div>
                <div className="spec-label">G'ildirak formulasi</div>
              </div>
            </div>
          </section>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default ProductDetailPage;
