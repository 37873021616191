import { Link } from "react-router-dom";

export function Footer() {
    return (
      <footer
        style={{
          backgroundColor: "#222",
          color: "white",
          padding: "40px 0 20px",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "0 20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {/* Logo and Contact */}
          <div style={{ width: "250px", marginBottom: "30px" }}>
            <div style={{ marginBottom: "20px" }}>
              <img src="/images/logo.png" alt="SamAuto" style={{ height: "40px" }} />
            </div>
            <div style={{ marginBottom: "15px", fontSize: "18px", fontWeight: "bold" }}>+998 (97) 488 06 05   
            <br />
            +998 (94) 329 07 07
            <br />
            +998 (97) 804 80 48



            </div>
            <div style={{ marginBottom: "15px" }}>Ijtimoiy tarmoqlar:</div>
            <div style={{ display: "flex", gap: "15px" }}>
              {/* Social Media Icons */}
              <a href="https://t.me/qarshiisuzu" style={{ color: "white" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                  <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                </svg>
              </a>
            
              <a href="https://www.instagram.com/avto_saltanat_qarshi" style={{ color: "white" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
              </a>
           
            </div>
          </div>
  
          {/* Navigation Columns */}
          <div style={{ width: "180px", marginBottom: "30px" }}>
            <h3 style={{ fontSize: "18px", marginBottom: "15px" }}>Modellar qatori</h3>
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/products" style={{ color: "white", textDecoration: "none" }}>
                  Avtobuslar
                </Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/products" style={{ color: "white", textDecoration: "none" }}>
                  Yuk avtomobillari
                </Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/products" style={{ color: "white", textDecoration: "none" }}>
                  Maxsus texnikalar
                </Link>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/products" style={{ color: "white", textDecoration: "none" }}>
                  Pikaplar
                </Link>
              </li>
            </ul>
          </div>
  
          <div style={{ width: "180px", marginBottom: "30px" }}>
            <h3 style={{ fontSize: "18px", marginBottom: "15px" }}>Maxsus takliflar</h3>
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              <li style={{ marginBottom: "10px" }}>
                <Link to="/sales" style={{ color: "white", textDecoration: "none" }}>
                  Aksiyalar
                </Link>
              </li>
            
            </ul>
          </div>
  
          {/* <div style={{ width: "180px", marginBottom: "30px" }}>
            <h3 style={{ fontSize: "18px", marginBottom: "15px" }}>Xizmat</h3>
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              <li style={{ marginBottom: "10px" }}>
                <a href="#" style={{ color: "white", textDecoration: "none" }}>
                  Mahalliylashtirish
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a href="#" style={{ color: "white", textDecoration: "none" }}>
                  Kafolat
                </a>
              </li>
              <li style={{ marginBottom: "10px" }}>
                <a href="#" style={{ color: "white", textDecoration: "none" }}>
                  Ehtiyot jihozlar
                </a>
              </li>
              {/* <li style={{ marginBottom: "10px" }}>
                <a href="#" style={{ color: "white", textDecoration: "none" }}>
                  Diler topish
                </a>
              </li> 
            </ul>
          </div> */}
  
          <div style={{ width: "180px", marginBottom: "30px" }}>
            <h3 style={{ fontSize: "18px", marginBottom: "15px" }}>SamAuto dunyosi</h3>
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              {/* <li style={{ marginBottom: "10px" }}>
                <a href="#" style={{ color: "white", textDecoration: "none" }}>
                  Yangiliklar
                </a>
              </li>
             */}
              <li style={{ marginBottom: "10px" }}>
                <Link to="/contact-us" style={{ color: "white", textDecoration: "none" }}>
                  Bog`lanish
                </Link>
              </li>
             
             
            </ul>
          </div>
        </div>
  
        {/* Copyright */}
        <div
          style={{
            maxWidth: "1200px",
            margin: "20px auto 0",
            padding: "20px 20px 0",
            borderTop: "1px solid #444",
            fontSize: "14px",
            color: "#aaa",
          }}
        >
          avtosaltanat.uz - Barcha huquqlar himoyalangan.
        </div>
      </footer>
    )
  }
  
  